@import "../../../../../../styles/vars_mixins";

.claim-credit {
  &-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-back {
      cursor: pointer;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    @include themify(night) {
      color: $gray-400;
    }
  }

  .key-or-hot {
    padding: 0;
    margin: 1rem 0 0;

    .input-group {
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}